<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <nav class="px-6 py-3 bg-secondary text-white lg:px-24 shadow-md">
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <router-link to="/">
          <img src="@/assets/logo-dark.png" alt="Logo" class="logo-dimensions" />
        </router-link>      
      </div>
      <div class="lg:hidden">
        <button @click="toggleMenu" class="text-white focus:outline-none">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
      </div>
      <div class="hidden lg:flex lg:gap-10 lg:text-md">
        <div
          v-for="link in links"
          :key="link.text"
          class="relative px-2 py-1 rounded transition-colors duration-100 flex-grow hover:bg-primary group"
          @mouseenter="showDropdown(link.text)"
          @mouseleave="hideDropdown"
        >
          <a :href="link.url" class="block w-full h-full group-hover:text-white">
            {{ link.text }}
          </a>

          <!-- Dropdown menu for Programs -->
          <div
            v-if="link.text === 'Programs'"
            class="absolute left-0 top-full pt-3 w-48 bg-secondary text-white rounded-md shadow-lg z-10 transition-all duration-300 ease-in-out origin-top"
            :class="{ 'opacity-0 invisible scale-95': !isDropdownOpen, 'opacity-100 visible scale-100': isDropdownOpen }"
            @mouseenter="keepDropdownOpen"
            @mouseleave="hideDropdown"
          >
            <a
              v-for="submenu in programsSubmenu"
              :key="submenu.text"
              :href="submenu.url"
              class="block px-4 py-2 text-sm hover:bg-primary hover:text-white transition-colors duration-100 first:rounded-t-md last:rounded-b-md"
            >
              {{ submenu.text }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <transition
      enter-active-class="transition-all duration-200 ease-linear"
      enter-from-class="opacity-0 -translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition-all duration-200 ease-linear"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 -translate-y-1"
    >
      <div v-if="isMenuOpen" class="mt-4 lg:hidden">
        <div
          v-for="link in links"
          :key="link.text"
          class="block px-2 py-2 text-center rounded hover:bg-secondary transition-colors duration-100"
        >
          <div 
            v-if="link.text !== 'Programs'" 
            :href="link.url" 
            class="hover:text-gray-400"
          >
            {{ link.text }}
          </div>
          <div v-else @click="toggleProgramsSubmenu" class="cursor-pointer">
            {{ link.text }}
          </div>
          <transition
            v-if="link.text === 'Programs'"
            enter-active-class="transition-all duration-200 ease-linear"
            enter-from-class="opacity-0 max-h-0"
            enter-to-class="opacity-100 max-h-40"
            leave-active-class="transition-all duration-200 ease-linear"
            leave-from-class="opacity-100 max-h-40"
            leave-to-class="opacity-0 max-h-0"
          >
            <div v-if="isProgramsSubmenuOpen" class="overflow-hidden">
              <div
                v-for="submenu in programsSubmenu"
                :key="submenu.text"
                class="block px-4 py-2 text-center hover:bg-secondary transition-colors duration-100"
              >
                <a :href="submenu.url" class="hover:text-gray-400">{{ submenu.text }}</a>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </nav>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup() {
    const links = ref([
      { text: 'About', url: '/#about' },
      { text: 'Testimonials', url: '/#testimonials' },
      { text: 'Programs', url: '/#programs' },
      { text: 'Merch', url: '/merch' },
    ])

    const programsSubmenu = ref([
      { text: 'Video Shot Analysis', url: '/programs/shooting' },
      { text: 'Strength & Conditioning', url: '/programs/strength' },
    ])
    const isProgramsSubmenuOpen = ref(false)

    const toggleProgramsSubmenu = () => {
      isProgramsSubmenuOpen.value = !isProgramsSubmenuOpen.value
    }
    const isMenuOpen = ref(false)
    const isDropdownOpen = ref(false)

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value
    }

    const showDropdown = (linkText: string) => {
      if (linkText === 'Programs') {
        isDropdownOpen.value = true
      }
    }

    const hideDropdown = () => {
      setTimeout(() => {
        isDropdownOpen.value = false
      }, 150)
    }

    const keepDropdownOpen = () => {
      isDropdownOpen.value = true
    }

    return { links, programsSubmenu, isMenuOpen, isDropdownOpen, toggleMenu, showDropdown, hideDropdown, toggleProgramsSubmenu, isProgramsSubmenuOpen, keepDropdownOpen }
  }
})
</script>

<style scoped>
.logo-dimensions {
  width: 180px;
}

/* Remove this style as it's no longer needed */
/* nav .relative:hover .absolute {
  display: block;
} */
</style>
